import React, { Component } from 'react';
import { Panel, Button, VuMeter, Table, Box} from "@telosalliance/ui-core";
import { pushNotification } from "@telosalliance/ui-core-framework";
import Grid from '@material-ui/core/Grid';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import SettingsIcon from '@material-ui/icons/Settings';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TelosWebRTC, MediaDevices }  from "../component/WebRTC/teloswebrtc";
import { format, addMinutes  } from 'date-fns'
import IconButtonMUI from '@material-ui/core/IconButton';

class WebRtcLiteClient extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isValid:false,
            isLoaded:false,
            vuLocalL: -50,
            vuLocalR: -50,
            vuRemoteL: -50,
            vuRemoteR: -50,
            audioInputs:[],
            audioOutputs:[],
            videoInputs:[],
            selAudioIn: 'default',
            selAudioOut: 'default',
            dataChan:'',
            connectionInfo: 'Ready...',
            isAudioInMuted:false,
            isAudioOutMuted: false,
            isActive: false,
            serverTime: {nowTime: 0, upTime: 0},
            getStatsInfo: null,
            localTime: "",
            conTime: "",
            inviteName:"",
            sessionId:"",
            autoReconnect:false,
            uiView: "call",
            isOnCall:false,
            isConnected: false,
            autoGainControl:false,
            echoCancellation:false,
            noiseSuppression:false,
         }

         this.mediaDevices = null;
         this.telosWebRTC = null;   
    }


    getSelectedAudioInput(){

        let item = localStorage.getItem('lc_audioDevInput')
  
        if(!item){
          item = "default";
        }
  
        this.setState({selAudioIn: item});
  
        return item;  
    }

    setSelectedAudioInput(item){
    }

    getSelectedAudioOutput(){

        let item = localStorage.getItem('lc_audioDevOutput')
  
        if(!item){
          item = "default";
        }
  
        this.setState({selAudioOut: item});
  
        return item;  
    }

    componentDidMount() {

      if(this.props.mode === 1) {
        this.initMode1();
      } else if(this.props.mode === 2) {
        this.initMode2();
      }

    }

    initMode1() {
      
      // shage CSS for this page only
      var css = document.querySelector('.main');
      css.style.setProperty('overflow', 'hidden');

        /*var simpleclient = {
            room: "axia123",
            bitrate: 128,
            chan: 2,
            autoReconnect: true,
            autoGainControl:false,
            echoCancellation:false,
            noiseSuppression:false,
        }

        //all except room is optinal

        const jsonStr = JSON.stringify(simpleclient);
        const jsonStrBase64 = new Buffer.from(jsonStr).toString('base64');

        console.log("jsonStrBase64", jsonStrBase64);
      */
        try{
            const sinv = window.atob(this.props.urlParams); 
            const invJson = JSON.parse(sinv);
    
          //  console.log("invJson", invJson);
    
            const audioCfg = {
                bitrate: (invJson.bitrate === undefined) ? 64 : invJson.bitrate,
                chan: (invJson.chan === undefined) ? 1 : invJson.chan,
            }

            this.setState({
              isValid:true, 
              sessionId: invJson.room, 
              audioConfig: audioCfg, 
              autoReconnect: (invJson.autoReconnect === undefined) ? false : true,
              autoGainControl: (invJson.autoGainControl === undefined) ? false : true,
              echoCancellation: (invJson.echoCancellation === undefined) ? false : true,
              noiseSuppression: (invJson.noiseSuppression === undefined) ? false : true,
            }, () => {
                this.initClient();
            });

        } catch(err) {
            this.setState({isValid:false});
        }
    }

    initMode2() {
        //console.log("initMode2", this.props.urlParams);

        try{
          const invJson = this.props.urlParams;
  
          const audioCfg = {
              bitrate: (invJson.br === undefined) ? 64 : invJson.br,
              chan: (invJson.st === undefined) ? 1 : (parseInt(invJson.st) === 1) ? 2 : 1,
          }

          this.setState({
            isValid:true, 
            sessionId: invJson.room, 
            audioConfig: audioCfg, 
            autoReconnect: (invJson.ar === undefined) ? false : (parseInt(invJson.ar) === 1) ? true : false,
            autoGainControl: false,
            echoCancellation: false,
            noiseSuppression: false,
          }, () => {
              this.initClient();
          });

      } catch(err) {
          this.setState({isValid:false});
      }
    }

    componentWillUnmount(){
        this.telosWebRTC.closeWebRTC();
    }


    initClient() {

        this.mediaDevices = new MediaDevices();
  
        this.mediaDevices.on('audioOut', (devices) => {
          this.setState({audioOutputs: devices}, () => {
            // wait until set, then set the selected devices
            this.setState({selAudioOut: this.getSelectedAudioOutput()})
          });
        });
    
        this.mediaDevices.on('audioIn', (devices) => {
          this.setState({audioInputs: devices}, () => {
            // wait until set, then set the selected devices
            this.setState({selAudioIn: this.getSelectedAudioInput(), isLoaded:true})
          });
        });
    
        this.mediaDevices.on('videoIn', (devices) => {
          this.setState({videoInputs: devices}); 
        });
  
        this.mediaDevices.on('error', (error) => {
          console.log("mediaDevices error", error);
        });
  
    
  
  
        /* TelosWebRTC */
  
        this.telosWebRTC = new TelosWebRTC();
  
        this.telosWebRTC.on('localVu', (data) => {
          this.setState({vuLocalL: data.dbL, vuLocalR: data.dbR})
        });
  
        this.telosWebRTC.on('remoteVu', (data) => {
          this.setState({vuRemoteL: data.dbL, vuRemoteR: data.dbR})
        });
  
        this.telosWebRTC.on('data', (data) => {
        //  console.log("data", data);
        });
  
        this.telosWebRTC.on('data-bin', (data) => {
         // console.log("data binary", data);
        });
  
        this.telosWebRTC.on('audio', (data) => {
          this.setState({isAudioInMuted: data.audioInMuted, isAudioOutMuted: data.audioOutMuted})
        });
        
  
        this.telosWebRTC.on('status', (data) => {
  
          if(data.tp === 1 ) { // only look at "ConnectionState"
            this.setState({connectionInfo: data.msg })
          
              if(data.data === 8) { //autoDisconnect
                pushNotification("You have been disconnected due to another client connected to this session", 
                  { anchorOrigin:{ vertical: 'top', horizontal: 'center' }, variant: "warning", autoHideDuration: 10000 }
                )
              }
  
              if(data.data === 11) { //reconnecting
                
                this.setState({isOnCall:true});

                /* pushNotification("Reconnecting...", 
                  {  variant: "info" }
                )*/

              }
  
              if(data.data === 3) { //connected
               /* pushNotification("Connected...", 
                  {  variant: "success" }
                )*/
              }
  
  
              if(data.data === 1 || data.data === 2 || data.data === 3 || data.data === 11) {
                this.setState({isActive:true});
              }

              if(data.data === 1) {
                this.setState({isConnected:true});
              }
              
  
              if(data.data === 4 || data.data === 5 || data.data === 6 || data.data === 7 || data.data === 8 || data.data === 9) {
                this.setState({isActive:false, localTime: "", conTime: "", isOnCall:false, isConnected:false});
              }
          }
  
          // GetStats
          if(data.tp === 13) { // only look at "GetStats"
            
            //console.log("stats",data.data);
            
            if(data.data) { 
                this.setState({getStatsInfo: data.data})  
            }
         }
  
        
        });
  
        this.telosWebRTC.on('error', (error) => {
          console.log("error", error);
        });
  
        this.telosWebRTC.on('debug', (data) => {
         // console.log("debug", data);
        });
  
        this.telosWebRTC.on('time', (data) => {
          //console.log("time", data);
  
          var _lt = format(new Date(data.nowTime), 'HH:mm');
  
          var upDate = new Date(data.upTime);
          var _ct = format(addMinutes(upDate, upDate.getTimezoneOffset()), 'HH:mm');
  
          this.setState({serverTime: data, localTime: _lt, conTime: _ct});
        });
  
        this.telosWebRTC.on('eject', (data) => {
          //console.log("eject", data);
          this.props.refreshCallback(this.props.inviteId);
        });
        
  
  
        /*
        const initCfg = {
          autoResume: false,
          debug:false,
        }
  
        this.telosWebRTC.initWebRTC(initCfg);
        */
      }


    doConnect = () => {

        let wsUrl = document.getElementsByTagName('base')[0].baseURI; //get the base url in use.
        wsUrl = wsUrl.replace('http:','ws:'); //change protocol
        wsUrl = wsUrl.replace('https:','wss:'); //change protocol (secure)
        wsUrl += "signal"; // add the webservice endpoint.

        const config = {
          signal: wsUrl,
          sessionId: this.state.sessionId,
          inviteId: '',
          title: this.state.inviteName,
          audioIn: this.getSelectedAudioInput(),
          audioOut: this.getSelectedAudioOutput(),
          sendBitRate: parseInt(this.state.audioConfig.bitrate),
          sendStereo: (parseInt(this.state.audioConfig.chan) === 2) ? true : false,
          receiveStereo: true,
          debug: false,
          autoReconnect: this.state.autoReconnect,
          getStats:true,
          autoGainControl: this.state.autoGainControl,
          echoCancellation: this.state.echoCancellation,
          noiseSuppression: this.state.noiseSuppression,
          vumeters: true,
          workletPath: "worklet/",
        }
    
        this.telosWebRTC.connectWebRTC(config);
  
        this.setState({isActive:true, isOnCall:true});
  
    }

    doClose = () => {

        this.setState({isOnCall:false});
        this.telosWebRTC.closeWebRTC();
    }
   
    doTest = () => {
    
    }
   
    changeAudioInput = (event) => {

        var item = event.target.value;
        localStorage.setItem('lc_audioDevInput', item);
        this.setState({selAudioIn: item});
    };

    changeAudioOutput = (event) => {

        var item = event.target.value;
        localStorage.setItem('lc_audioDevOutput', item);
        this.setState({selAudioOut: item});
    };

    doAudioInMute = (muted) => {
        this.telosWebRTC.muteAudioIn(muted);
    }

    doAudioOutMute = (muted) => {
        this.telosWebRTC.muteAudioOut(muted);
    }

    bytesToSize(bytes) {
        var k = 1000;
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes <= 0) {
            return '0 Bytes';
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10);
        
        if(!sizes[i]) {
            return '0 Bytes';
        }

        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    }

   

    renderWebRTCfull() { 

        if(this.state.uiView !== "call"){
          return null;
        }

        if(!this.state.isValid){
            return null;
        }

        if(!this.state.audioInputs){
          return null;
        }

        return (
        <React.Fragment>
          <center>
          <Panel style={{ minWidth: 360, maxWidth: 1200, padding:"0px", top: "-65px", position:"relative" }}>
          
          <Grid 
            style={{ marginTop:"-10px",  marginBottom:"-10px"}}
            container 
            spacing={4}
          >   
    
            <Grid item xs={12} md={3} sm={12}> 

              <Box variant="solid" style={{ paddingBottom:"20px"}}>

                {this.state.isOnCall ? (
                  <Button style={{position:"relative", width:"170px", padding:"10px", margin:"5px", fontWeight:"bold", zIndex:"2"}} color="light-red" outlined onClick={()=>{ this.doClose() }} ><><CallEndIcon style={{position:"relative", top:"5px"}} /><span style={{position:"relative", top:"-2px", marginLeft:"5px"}}>END CALL</span></></Button>
                ) : (
                  <Button style={{position:"relative", width:"170px", padding:"10px", margin:"5px", fontWeight:"bold", zIndex:"2"}} color="green" outlined onClick={()=>{ this.doConnect() }} ><><CallIcon style={{position:"relative", top:"4px"}} /><span style={{position:"relative", top:"-2px", marginLeft:"5px"}}>CONNECT</span></></Button>
                )}

                {this.state.isConnected ? (
                  <div style={{paddingTop:"15px", paddingBottom:"15px"}}>{this.state.connectionInfo} { this.state.conTime }</div>
                ) : (
                  <div style={{paddingTop:"15px", paddingBottom:"15px"}}>{this.state.connectionInfo}</div>
                )}

                <IconButtonMUI tooltip="Settings" style={{ marginTop:"3px", backgroundColor:"#3c4043", color:"#ffffff", marginRight:"5px"}}  onClick={(event) => { 
                  this.setState({uiView:"setup"});
                }}><SettingsIcon fontSize="small"/></IconButtonMUI>


                <IconButtonMUI tooltip="Call Info" style={{ marginTop:"3px", backgroundColor:"#3c4043", color:"#ffffff", marginLeft:"5px"}}  onClick={(event) => { 
                  this.setState({uiView:"info"});
                }}><InfoOutlinedIcon fontSize="small"/></IconButtonMUI>

              </Box>

  
            </Grid> 


            <Grid item xs={12} md={9} sm={12}>  

                <Box variant="solid" style={{  borderRadius: "4px", paddingBottom:"20px" }}>
                <div className="lnVuGridLite">
                  <Grid container spacing={10} >
                  
                    <Grid item xs={12} md={6} sm={12}>

                          <span className="lnSmallLabel">Audio In (Local)</span>
                          <Table 
                          style={{position:"relative", marginTop:"-25px", left:"-250px"}}
                          params 
                          striped={false}
                          rows={[
                            [
                          <>
                            <VuMeter 
                            style={{position:"relative", left:"-37px"}}
                            value={this.state.vuLocalL}
                            peakHoldValue={this.state.vuLocalL}
                            floorLevel="-50"
                            label="L"
                            attack={0.15}
                            decay={0.04}
                            peakHoldLineWidth={1}
                            zone1LevelColor="#f1f1f1"
                            peakHoldColor="#f1f1f1"
                            backgroundColor="#101010"
                            height={300}
                            width={20}
                            horizontal
                          />
                          </>],
                          [<>
                          <VuMeter
                            style={{position:"relative", marginTop:"-12px"}}
                            value={this.state.vuLocalR}
                            peakHoldValue={this.state.vuLocalR}
                            floorLevel="-50"
                            label="R"
                            attack={0.15}
                            decay={0.04}
                            peakHoldLineWidth={1}
                            zone1LevelColor="#f1f1f1"
                            peakHoldColor="#f1f1f1"
                            backgroundColor="#101010"
                            height={300}
                            width={20}
                            horizontal
                            showTicks="right"
                        />
                          </>]
                          ]}
                        /> 

                          {this.state.isAudioInMuted ? (
                            <IconButtonMUI tooltip="UnMute Mic" style={{ marginTop:"15px", marginLeft:"50px", backgroundColor:"#ea4335", color:"#ffffff"}}  onClick={(event) => { this.doAudioInMute(false) }} ><MicOffIcon fontSize="small"/></IconButtonMUI>
                          ) : (
                            <IconButtonMUI tooltip="Mute Mic" style={{ marginTop:"15px",  marginLeft:"50px", backgroundColor:"#3c4043", color:"#ffffff"}}  onClick={(event) => { this.doAudioInMute(true) }} ><MicIcon fontSize="small"/></IconButtonMUI>
                          )}

                    </Grid>

                    <Grid item xs={12} md={6} sm={12}>
                        
                          <span className="lnSmallLabel">Audio Out (Remote)</span>
                          <Table 
                          style={{position:"relative", marginTop:"-25px", left:"-280px"}}
                          params 
                          striped={false}
                          rows={[
                            [
                          <>
                            <VuMeter 
                            style={{position:"relative", left:"-37px"}}
                            value={this.state.vuRemoteL}
                            peakHoldValue={this.state.vuRemoteL}
                            floorLevel="-50"
                            //showTicks="left"
                            label="L"
                            attack={0.15}
                            decay={0.04}
                            peakHoldLineWidth={1}
                            zone1LevelColor="#7ac900"
                            peakHoldColor="#7ac900"
                            backgroundColor="#101010"
                            height={300}
                            width={20}
                            horizontal
                          />
                          </>],
                          [<>
                            <VuMeter
                              style={{position:"relative", marginTop:"-12px"}}
                              value={this.state.vuRemoteR}
                              peakHoldValue={this.state.vuRemoteR}
                              floorLevel="-50"
                              label="R"
                              attack={0.15}
                              decay={0.04}
                              peakHoldLineWidth={1}
                              zone1LevelColor="#7ac900"
                              peakHoldColor="#7ac900"
                              backgroundColor="#101010"
                              height={300}
                              width={20}
                              horizontal
                              showTicks="right"
                          />
                            </>]
                            ]}
                          />


                          {this.state.isAudioOutMuted ? (
                            <IconButtonMUI tooltip="UnMute Speaker" style={{ marginTop:"15px", backgroundColor:"#ea4335", color:"#ffffff"}}  onClick={(event) => { this.doAudioOutMute(false) }} ><VolumeOffIcon fontSize="small"/></IconButtonMUI>
                          ) : (
                            <IconButtonMUI tooltip="Mute Speaker" style={{ marginTop:"15px", backgroundColor:"#3c4043", color:"#ffffff"}}  onClick={(event) => { this.doAudioOutMute(true) }} ><VolumeUpIcon fontSize="small"/></IconButtonMUI>
                          )}

                        </Grid> 
        
                    </Grid>
                  </div>
                  </Box>
    
            </Grid>
          </Grid> 

          </Panel>
          </center>

      </React.Fragment> );
    }
      

    renderSettingsView() {

      if(this.state.uiView !== "setup"){
        return null;
      }

        return(
          <>
          <center>
          <Panel title="Audio Settings" id={`cpnl1_${this.state.inviteSession}`} style={{ minWidth: 360, maxWidth: 1200, padding:"0px", top: "-45px", position:"relative" }}>

          <Table 
            params 
            striped={false}
            rows={[
              ["Audio In", 
              <>
                <select
                name="cmdAudioIn"
                key="cmdAudioIn"
                className="uic-input"
                onChange={this.changeAudioInput}
                value={this.state.selAudioIn}
                disabled={this.state.isActive}
                >
                  {this.state.audioInputs.map((device, index) => (
                    <option
                      value={device.deviceId}
                      key={index}
                    >
                      {device.name}
                    </option>
                  ))}

                </select>
              </>
              ],
              ["Audio Out", 
              <>
                <select
                  className="uic-input"
                  onChange={this.changeAudioOutput}
                  name="cmdAudioOut"
                  key="cmdAudioOut"
                  value={this.state.selAudioOut}
                  disabled={this.state.isActive}
                >
                  {this.state.audioOutputs.map((device, index) => (
                    <option
                      value={device.deviceId}
                      key={index}
                    >
                      {device.name}
                    </option>
                  ))}

                </select>
              </>
              ],
            ]}
            />  

           

            
            <div className="uic-modal-btnGroup" >
          
            <span className="lnSmallLabel"><i>Must be set before connecting</i></span>

              <Button outlined color="blue" disabled={this.state.isActive} onClick={()=>{
                this.setState({uiView:"call"});
              }} style={{marginLeft:"15px"}}> Save </Button>
            
              <Button  outlined onClick={()=>{
                this.setState({uiView:"call"});
              }} style={{marginLeft:"5px"}}> Close </Button>

            </div>

            

          </Panel>  
          </center>
          </>
        )


    }


    renderCallInfo() {

      if(this.state.uiView !== "info"){
        return null;
      }
        
     // console.log("stats", this.state.getStatsInfo);

      if(this.state.isActive && this.state.getStatsInfo && this.state.getStatsInfo.connectionType && this.state.getStatsInfo.audio && this.state.getStatsInfo.bandwidth && this.state.getStatsInfo.datachannel) {

      return ( <React.Fragment>
        <center>
         <Panel  id="infoPanel" style={{ fontSize:"14px", minWidth: 360, maxWidth: 1200, padding:"0px", top: "-60px", position:"relative" }}>

            <Table 
              params 
              striped={false}
              rows={[
                ["Local", 
                this.state.getStatsInfo.connectionType.local.candidateType + " (" + this.state.getStatsInfo.connectionType.local.transport.join(', ') + ") " +  this.state.getStatsInfo.connectionType.local.ipAddress
                ],
                ["Remote", 
                this.state.getStatsInfo.connectionType.remote.candidateType + " (" + this.state.getStatsInfo.connectionType.remote.transport.join(', ') + ") " +  this.state.getStatsInfo.connectionType.remote.ipAddress
                ],
                ["Audio", 
                "BitRate: " + this.state.getStatsInfo.bandwidth.availableSendBandwidth/1000 + " kbps,  Sent: " + this.bytesToSize(this.state.getStatsInfo.audio.bytesSent) + ", Received: " + this.bytesToSize(this.state.getStatsInfo.audio.bytesReceived)
                ],
                ["Quality", 
                "Packet Loss: " + this.state.getStatsInfo.audio.packetsLost + ", Latency: " + this.state.getStatsInfo.audio.latency
                ],
                ["Data", 
                "State: " + this.state.getStatsInfo.datachannel.state
                ],
              ]}
              />
            
            <div className="uic-modal-btnGroup" style={{top: "-20px", position:"relative" }}>
              <Button outlined onClick={()=>{ this.setState({uiView: "call"} ) }}>Close</Button>
            </div>
            </Panel>
            </center>
          </React.Fragment>
          );

        } else {
          return (<>

          <center>
          <Panel  id="infoPanel" style={{ fontSize:"14px", minWidth: 360, maxWidth: 1200, padding:"0px", top: "-60px", position:"relative" }}>
            
            <center>
            <h2>Not Connected</h2>

            <br/><br/>

           
            <Button outlined onClick={()=>{ this.setState({uiView: "call"} ) }}>Close</Button>
          
            <br/> <br/> <br/> <br/><br/> <br/>

            </center>
            </Panel>
            </center>
          </>);
        }
        
    }



    renderInvalidSession() {

        if(!this.state.isValid){
        return ( <React.Fragment>
            <center style={{ marginTop:"0px" }}>
            <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
            <h2>Invalid</h2>
            </Panel>
            </center>
            </React.Fragment>);
        }
    }


    render() { 
        return ( 
            <>
              {this.renderInvalidSession()}
              {this.renderSettingsView()}
              {this.renderCallInfo()}
              {this.renderWebRTCfull()}
            </>
         );
    }
}
 
export default WebRtcLiteClient