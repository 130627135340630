import React, { Component } from "react";
import { Panel } from "@telosalliance/ui-core";
import WarningIcon from "@material-ui/icons/Warning";

import Users from "./Users";

class FirstUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      agentGroupGUID: null,
      userGUID: null,
      userRole: null,
      userTimeZone: null,
    };
  }

  render() {
    return (
      <>
        <div id="firstLoginDiv">
        <br />
        <Panel className="panelInfo" style={{ backgroundColor: "#df6320" }}>
          <WarningIcon fontSize="large" style={{ color: "#dedede" }} />
          <span
            style={{
              color: "#ffffff",
              top: "-10px",
              marginLeft: "5px",
              position: "relative",
              fontWeight: "500",
            }}
          >
            Please provide details below to create an administrator account.
            After saving, you will be logged out. Then login again with your
            credentials and continue the set up.
          </span>
        </Panel>

        <br />

        <Users
          isFirstLogin={true}
          uid={this.props.uid}
        />

        <br />
        </div>
      </>
    );
  }
}

export default FirstUse;
