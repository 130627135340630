import React, { Component} from 'react';
import { Link } from "react-router-dom";


import {
Row,
Panel,
CollapsablePanel,
Table,
Button,
Toggle,
IconButton,
Modal,
InfoTooltip 
} from "@telosalliance/ui-core";

import { 
    pushNotification,  
    confirm, 
    alert
   // prompt
  } from "@telosalliance/ui-core-framework";
  


import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import axios from "axios";
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
//import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
//import LayersIcon from '@material-ui/icons/Layers';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import CallIcon from '@material-ui/icons/Call';
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CollapsablePanelDashboard from "../component/CollapsablePanelDashboard";

import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
//import PhoneIcon from '@material-ui/icons/Phone';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
//import PhonePausedIcon from '@material-ui/icons/PhonePaused';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import CallEndIcon from '@material-ui/icons/CallEnd';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import WarningIcon from '@material-ui/icons/Warning';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

//import { it, tr } from 'date-fns/locale';


//import {ws} from "../component/GlobalUtils";

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
    
        this.nowDate = new Date();
        this.endDate = new Date();
        this.endDate.setHours(this.nowDate.getHours() + 6);
        

        this.state = {
            isAuthorized : props.isLoggedIn,
            agentGroupGUID: '',
            userGUID:'',
            userRole: '',
            userTimeZone:'',
            userName:'',
            agentGroupItems:[],
            agentGroupCount:0,
            curAgentGroupStart:0,
            curAgentGroupPage:25,
            agentGroupLoaded:false,
            inviteTitle:"",
            inviteTitlePrefix: "",
            inviteModalOpen:false,
            inviteModalSession: null,
            inviteEmail: "",
            inviteEmailPlaceholder:"Email address", 
            inviteName: "",
            inviteValidated: false,
            inviteValidFrom: Date.parse(this.nowDate),
            inviteValidTo: Date.parse(this.endDate),
            inviteMicMode: 1,
            inviteAudioQ: "0|0",
            inviteDisablePassword: false,
            inviteDisableEmail: false,
            isEditMode:false,
            inviteGUID: null,
            inviteSessionGUID:null,
            signalRooms: [],
            ddmenuAnchorEl:null,

            emailServerType: "smtp",
            emailSmtp : null,
            emailMailJet: null,
            emailMailGun: null,
            miscConfig: null,
            defaultBaseUrl:"",
            hasAppSettings: true,
            iceConfigs:[],
            iceConfigItems:[],
            isIceLoaded:false,
            serverInfo: {
              timeUtcStr:"",
              upTimeStr:"",
              mem: "",
              versionTag:""
            },
            beaconInfo: {
              tag:"",
              version:""
            },
            signalInfo: null

        };

        this.ws = null;
        
    }


    initWebSocket() {

        let wsUrl = document.getElementsByTagName('base')[0].baseURI; //get the base url in use.
        wsUrl = wsUrl.replace('http:','ws:'); //change protocol
        wsUrl = wsUrl.replace('https:','wss:'); //change protocol (secure)
        wsUrl += "beaconstatus"; // add the webservice endpoint.

        this.ws = new WebSocket(wsUrl);
   
      this.ws.onopen = () => {
        console.log("ws OK:", wsUrl);
      };

      this.ws.onerror = (err) => {
        console.log(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );
  
        if (this.ws && this.ws.readyState === 1) {
          this.ws.close();
        }
      };

      this.ws.onmessage = (event) => {
        const jData = JSON.parse(event.data);
     //   console.log("jData", jData);
        switch(jData.tp) {
          case 0: //time
            //console.log("ws time", event.data);
            break;

          case 1: //"rooms":

            try {
                const rooms = jData.data;

                if(rooms) {
                
                  rooms.forEach((roomItem) => {
                    const agis = [...this.state.agentGroupItems];
                    var foundItem =  agis.find(agItem => agItem.invites.some(item => item.inviteGUID === roomItem.liveNetPeer.InviteId));
                    if(foundItem){
                      var foundInv = foundItem.invites.find(x => x.inviteGUID === roomItem.liveNetPeer.InviteId);
      
                      if(roomItem.liveNetPeer.peerInfo) {
      
                        var peerInfo = JSON.parse(roomItem.liveNetPeer.peerInfo);
                        if(peerInfo) {
      
                          foundInv.audioInMuted = peerInfo.audioInMuted;
                          foundInv.audioOutMuted = peerInfo.audioOutMuted;
                          foundInv.connectionState = peerInfo.connectionState;
      
                          this.setState({agentGroupItems: agis});
                        }
                      }
                    }
      
                  });
                }
            } catch(err) {
                console.log("ERROR: Dashboad - Invite status show:" + err.message);
            }

            break;
          
          case 2: //"removepeer"  
            try {
              const room = jData.data;
              if(room) {
                const agis = [...this.state.agentGroupItems];
                var foundItem =  agis.find(agItem => agItem.invites.some(item => item.inviteGUID === room.InviteId));
                if(foundItem){
                var foundInv = foundItem.invites.find(x => x.inviteGUID === room.InviteId);
                    foundInv.audioInMuted = false;
                    foundInv.audioOutMuted = false;
                    foundInv.connectionState = 0;

                    this.setState({agentGroupItems: agis});
                }
              }
            } catch(err) {
                console.log("ERROR: Dashboad - Invite status remove:" + err.message);
            }

            break;

          case 3: //serverinfo  

              const serverinfo = jData.data;

              if(serverinfo){
                this.setState({serverInfo:serverinfo});
              }

            break;

          case 4: //signalInfo  

            const signalinfo = jData.data;

            if(signalinfo){
              this.setState({signalInfo:signalinfo});
            }

            break;

           case 99: //reload invites
            this.loadAgentsInGroup(0);
            break;

            

          default:
              console.log("ws unknown", event.data);
              break;
        }

      };

    }
    
   

    getToken() {
        // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
        if(sessionStorage.getItem('tls-beacon-usertoken')) {
            const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
            const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

           // console.log("bakedJson",bakedJson)

            this.tokenHeader = { headers: { 'Authorization': 'Bearer ' + bakedJson.token, 'Content-Type': 'application/json' } }

            // use the callback in setState, this so we really have the values in the state before we use them.
            this.setState({ agentGroupGUID: bakedJson.agentGroupGuid, userGUID: bakedJson.userGuid, userRole: bakedJson.role, userTimeZone: bakedJson.timeZone, userName: bakedJson.firstName + " " + bakedJson.lastName }, () => {
                // load local stuff
                this.loadAppSettings();
                this.loadAgentsInGroup(0);
                this.loadIceItems(1);
                this.getSignalStatus();
                
            });
            
        } else {
            this.props.setAuthError("noToken");
        }
    }  

    loadIceItems(selId){

      axios.get("./api/iceitem?id=" + selId, this.tokenHeader).then((res) => {
          const configs = res.data;

          this.setState({
              iceConfigItems: configs,
              isIceLoaded: true,
          });

        }).catch((err) => {
          this.catchUnAuthorized(err,"loadIceItems");
        });

    }

    getSignalStatus(){

      axios.get("./api/beaconInfo", this.tokenHeader).then((res) => {

        const _beaconInfo = res.data;
        
        if(_beaconInfo) {
          this.setState({beaconInfo: _beaconInfo});
        }

      }).catch((err) => {
        this.catchUnAuthorized(err,"getSignalStatus");
      });

    }

    componentDidMount() {
      
      this.initWebSocket();
      this.getToken();
    }

    componentWillUnmount() {
      if (this.ws && this.ws.readyState === 1) {
        this.ws.close();
        console.log("ws closed")
      }
    }

    loadAppSettings = () => {
       
      axios.get("./api/getAppSettings", this.tokenHeader).then((res) => {
      
        const settings = res.data;

        if(settings){

            if(settings.misc.inviteDuration && settings.misc.inviteDurationUnit) {
              this.endDate = new Date();
              switch (settings.misc.inviteDurationUnit) {
                case "minutes":
                  this.endDate.setMinutes(this.nowDate.getMinutes() + settings.misc.inviteDuration)
                  break;
                case "hours":
                  this.endDate.setHours(this.nowDate.getHours() + settings.misc.inviteDuration)
                  break;
                case "months":
                  this.endDate.setMonth(this.nowDate.getMonth() + settings.misc.inviteDuration)
                  break;
                case "years":
                  this.endDate.setFullYear(this.nowDate.getFullYear() + settings.misc.inviteDuration)
                  break;
                default:
                  this.endDate.setHours(this.nowDate.getHours() + 6);
              }
            }

            this.setState({
              emailServerType: settings.emailServerType,
              emailSmtp: settings.emailSmtp,
              emailMailJet: settings.emailMailJet,
              emailMailGun: settings.emailMailGun,
              miscConfig: settings.misc,
              firstsConfig: settings.firsts,
              hasAppSettings: true,
              inviteValidTo: Date.parse(this.endDate),
            })
        }

      }).catch((err) => {

        if(err.response) {
          // 400 = BadRequest
          if (err.response.status === 400) {
            this.setState({hasAppSettings:false})
          } else {
            this.catchUnAuthorized(err,"loadAppSettings");
          }
        }
      });

    }


    loadAgentsInGroup(agentStart) {

       // this.setState({remotePeerInfo: []});

        axios.get("./api/invitesbygroup?agentGroupGUID="+ this.state.agentGroupGUID + "&start=" + agentStart + "&page=" + this.state.curAgentGroupPage, this.tokenHeader).then((res) => {

           this.setState({agentGroupItems: res.data.data, agentGroupCount: res.data.count, agentGroupLoaded: true });
           this.setState({curAgentGroupStart: agentStart, lastChanged: new Date().getTime()})


          }).catch((err) => {
            this.props.setAuthError(err);
        });
  
    }

    renderAgentsPagination() {

        let _start = this.state.curAgentGroupStart;
        let _page = this.state.curAgentGroupPage;
        let _count = this.state.agentGroupCount;

        // if number of items (Agents) are more than our page size then show pagnation buttons.
        if(_count > _page) {

            return ( 
                <>
                <Row>
                    <Button onClick={() => {
                        if(_start - _page >= 0) {
                            this.loadAgentsInGroup(_start - _page);
                        }
                    }}>{'<'}</Button>
                    <div style={{marginLeft: '5px', marginRight:'5px'}}> {_start/_page + 1} / { Math.ceil(_count/_page)} </div>
                    <Button onClick={() => {
                        if(_start + _page < _count) {
                           this.loadAgentsInGroup(_start + _page);
                        }
                    }}>{'>'}</Button>
                </Row>
                <Divider style={{marginTop:'5px'}} />
            </>);   
            
        }
            
    }

    doAudioInMute = (item, muteVal) => {
       
        const params = new URLSearchParams({
            id: item.inviteGUID,
            muteAudioIn: muteVal,
            muteAudioOut: 2,
          }).toString();
        

        axios.post("./api/peerMuteInvite?" + params, {}, this.tokenHeader).then((res) => {
            
           // this.loadAgentsInGroup(0);

        }).catch((err) => {
            console.log("ERR doAudioInMute", err)
        });
    }

    doAudioOutMute = (item, muteVal) => {

      const params = new URLSearchParams({
        id: item.inviteGUID,
        muteAudioIn: 2,
        muteAudioOut: muteVal,
      }).toString();
    

      axios.post("./api/peerMuteInvite?" + params, {}, this.tokenHeader).then((res) => {
          
         // this.loadAgentsInGroup(0);

      }).catch((err) => {
          console.log("ERR doAudioInMute", err)
      });

    }

    renderPhoneIcon = (conState) => {
     

        switch(conState){
          case 0:
          case 4: //OFF-LINE
              return (<CallEndIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
  
          case 6:    
          case 8:    
          case 9: //DISCONNECT
            return (<CallEndIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
  
          case 1:
          case 10:
          case 12:  //CONNECTING  
              return (<SettingsPhoneIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
  
          case 11:  // RECONNECT
            return (<PhoneCallbackIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);  
  
  
          case 2: //CONNECTING (waiting for peer)
              return (<PhoneForwardedIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
  
          case 3: //ON-LINE (connected)
              return (<PhoneInTalkIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
  
          case 5: //FAILED
              return (<PhoneMissedIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>);
        
  
          default: //N/A
            return (<PhoneDisabledIcon style={{position:"relative", top:"3px", color:"#48ACF4"}}/>); 
      
        }
    }

    renderIsConnected = (conState) => {
        if(conState===3) {
          return true;
        } else {
          return false;
        }
    }

    renderInviteLink = (item) => {

      //console.log("renderInviteLink: ", item);

      var showLink = true; 

      /* if(this.state.miscConfig){
        if(this.state.miscConfig.invitePasswordLength === 0) {
          showLink = true;
        }
      } */
      
      if(item.noPwd || showLink) {

        if(!item.inviteUrl) { return null; } 

        return <IconButton icon={AttachFileIcon} tooltip="Copy Invite Link to Clipboard" onClick={(event) => { 

          navigator.clipboard.writeText(item.inviteUrl).then(function() {
            if(item.noPwd) {
              alert(item.inviteName + "´s invite link now stored in Clipboard", {
                variant: "info",
              });
            } else {
              alert(item.inviteName + "´s invite link now stored in Clipboard. \n\nUse Password from invite email!", {
                variant: "info",
              });
            }
          }, function(err) {
            /* clipboard write failed */
            console.log('Error while copying to clipboard: ' + err);
          });

        }} />
        

      } else {
        return null;
      }
    } 

    renderHasPassword = (item) => {
      
      if(item.noPwd) {
        return (<LockOpenIcon style={{position:"relative", top:"3px", color:"#48ACF4"}} />);
      } else {
        return (<LockIcon style={{position:"relative", top:"3px", color:"#48ACF4"}} />);
      }

    }

    renderInviteItems = (invites, sessionItem) => {

        //console.log("invites", invites);

        //console.log("sessionItem",sessionItem);

        if(!invites) {
            return "No Invites";
        } 

        if(invites.length===0){
            return (<>
              <Button 
                color="blue" 
                onClick={() =>  this.setState({ 
                        inviteModalOpen: true, 
                        isEditMode:false, 
                        inviteModalSession: sessionItem,
                        inviteEmail: "",
                        inviteName: "",
                        inviteValidated: false,
                        inviteValidFrom: Date.parse(this.nowDate),
                        inviteValidTo: Date.parse(this.endDate),
                        inviteMicMode: 1,
                        inviteAudioQ: "0|0",
                        inviteTitlePrefix: "Create Invite:",
                        inviteTitle: sessionItem.sessionTitle,
                        inviteSessionGUID: sessionItem.sessionId,
                        inviteDisableEmail:false,
                        inviteDisablePassword:false
                    })}
                >
                <PersonAddIcon /> {sessionItem.sessionTitle}
            </Button>

            <div style={{marginTop:"15px"}}>No Invites found</div>
             </>);
        } 

        return(
            <React.Fragment>
            <Button 
                color="blue" 
                onClick={() =>  this.setState({ 
                        inviteModalOpen: true, 
                        isEditMode:false, 
                        inviteModalSession: sessionItem,
                        inviteEmail: "",
                        inviteName: "",
                        inviteValidated: false,
                        inviteValidFrom: Date.parse(this.nowDate),
                        inviteValidTo: Date.parse(this.endDate),
                        inviteMicMode: 1,
                        inviteAudioQ: "0|0",
                        inviteTitlePrefix: "Create Invite:",
                        inviteTitle: sessionItem.sessionTitle,
                        inviteSessionGUID: sessionItem.sessionId,
                        inviteDisableEmail:false,
                        inviteDisablePassword:false
                    })}
                >
                <PersonAddIcon /> {sessionItem.sessionTitle}
            </Button>

            <Table 
                params 
                striped
                alignLabelsLeft="left"
                headers={["","Pwd","Name","Email", "Link", "Valid", "Status", "","",""]}
                columnWidths={[25,25,200, null, 80, 50, 130,null,null,null]}
                rows={
                    invites.filter(ses => ses.inviteSessionId === sessionItem.sessionID).map((item) => 
                        [ this.renderPhoneIcon(item.connectionState), this.renderHasPassword(item), item.inviteName, item.inviteEmail, 
                            
                            this.renderInviteLink(item),
                            this.renderIsValid(item.isValid,item.vcalEvents[0].hasExpired, item.isBlocked),
                            this.renderItemConnectionStatus(item.connectionState,item.vcalEvents[0].hasExpired, item.isBlocked),

                            <React.Fragment>
                            
                            {item.audioInMuted ? (
                                <IconButton disabled={!this.renderIsConnected(item.connectionState)} style={{marginLeft:"10px", color:"#cc3e4a"}} icon={MicOffIcon} tooltip="UnMute Mic" onClick={(event) => { this.doAudioInMute(item, 0) }} />
                            ) : (
                                <IconButton disabled={!this.renderIsConnected(item.connectionState)} style={{marginLeft:"10px"}} icon={MicIcon} tooltip="Mute Mic" onClick={(event) => { this.doAudioInMute(item, 1) }} />
                            )}

                            {item.audioOutMuted ? (
                                <IconButton disabled={!this.renderIsConnected(item.connectionState)} style={{marginLeft:"10px", color:"#cc3e4a"}} icon={VolumeOffIcon} tooltip="UnMute Speaker" onClick={(event) => { this.doAudioOutMute(item,0) }} />
                            ) : (
                                <IconButton disabled={!this.renderIsConnected(item.connectionState)} style={{marginLeft:"10px"}} icon={VolumeUpIcon} tooltip="Mute Speaker" onClick={(event) => { this.doAudioOutMute(item,1) }} />
                            )}
                            
                            {item.isBlocked ? (
                                <IconButton
                                    
                                    style={{marginLeft:"10px", backgroundColor:"#cc3e4a"}}
                                    icon={BlockIcon}
                                    tooltip="Unblock Invite"
                                    onClick={async (event) => {

                                    event.preventDefault();
                                    if (
                                        await confirm(
                                        <>
                                            Sure you want to Unblock <b>{item.inviteName}</b>?
                                        </>,
                                        { variant: "warning" }
                                        )
                                    ) {
                                        this.handleUnBlockInvite(item.inviteGUID);
                                    }
                                    }}
                                    />
                                ) : (
                                    <IconButton
                                   
                                    style={{marginLeft:"10px"}}
                                    icon={BlockIcon}
                                    tooltip="Block and Eject Invite"
                                    onClick={async (event) => {

                                    event.preventDefault();
                                    if (
                                        await confirm(
                                        <>
                                            Sure you want to Block and Eject connection for <b>{item.inviteName}</b>?
                                        </>,
                                        { variant: "warning" }
                                        )
                                    ) {
                                        this.handleBanInvite(item.inviteGUID);
                                    }
                                    }}
                                    />     
                                )}

                              <IconButton
                                style={{marginLeft:"10px"}}
                                icon={EditIcon}
                                tooltip="Edit Invite"
                                onClick={(event) => {
                                  this.setState({ inviteTitle: sessionItem.sessionTitle})
                                  this.handleEditInvite(item.inviteGUID);
                                }}
                            />    
                            <IconButton
                                style={{marginLeft:"10px"}}
                                icon={DeleteIcon}
                                tooltip="Remove Invite"
                                onClick={async (event) => {

                                event.preventDefault();
                                if (
                                    await confirm(
                                    <>
                                        Sure you want to remove <b>{item.inviteName}</b>?
                                    </>,
                                    { variant: "warning" }
                                    )
                                ) {
                                    this.handleDeleteInvite(item.inviteGUID);
                                }
                                }}
                            />

                             <IconButton 
                                style={{marginLeft:"10px"}}
                                onClick={()=>{
                                this.handleOpenInvite(item.inviteUrl);
                                }} 
                                tooltip="Open Invite" icon={CallIcon} 
                            />

                              {this.renderInfoIconSession(item, sessionItem)}     

                            </React.Fragment>
                        ]
                    )
                }
            />
           
            </React.Fragment>
            
            
        );

    }

    handleOpenInvite=(url) => {
        window.open(url, "_blank");
    }

    handleBanInvite = (inviteGUID) => {

        const params = {
            guid: inviteGUID,
            blocked: true,
            msg: "sorry for the inconvenience"
            };

            axios.post("./api/blockInvite", params, this.tokenHeader).then((res) => {
            
                this.loadAgentsInGroup(0);

            }).catch((err) => {
            console.log("ERR handleBanInvite", err)
            });
    }

    handleUnBlockInvite = (inviteGUID) => {

        const params = {
            guid: inviteGUID,
            blocked: false,
            msg: ""
            };

            axios.post("./api/blockInvite", params, this.tokenHeader).then((res) => {
            
                this.loadAgentsInGroup(0);

            }).catch((err) => {
            console.log("ERR handleUnBlockInvite", err)
            });
    }

    handleDeleteInvite = (inviteGUID) => {

        // console.log("handleDeleteInvite", inviteGUID)

         axios.delete("./api/invite?guid=" + inviteGUID, this.tokenHeader).then((res) => {

            this.loadAgentsInGroup(0);

         }).catch((err) => {
           console.log("ERR handleDeleteInvite", err)
         });
    }


    handleEditInvite  = (inviteGUID) => {

        //get invite
        axios.get("./api/invite?inviteGUID=" + inviteGUID + "&events=true",  this.tokenHeader).then((res) => {
            const data = res.data.data;
            console.log("handleEditInvite", data)

            const audioCfg = JSON.parse(data.audioConfig);

            let _inviteDisableEmail = false;
            if(!data.inviteEmail) {
              _inviteDisableEmail = true;
            }

            let _inviteDisablePassword = false;
            if(data.inviteHash === data.invitePwdHash) {
              _inviteDisablePassword = true;
            }

            this.setState({ 
                inviteModalOpen: true, 
                isEditMode:true, 
                inviteModalSession: data,
                inviteEmail: data.inviteEmail,
                inviteName: data.inviteName,
                inviteValidated: false,
                inviteValidFrom: data.dtStart * 1000,
                inviteValidTo: data.dtEnd * 1000,
                inviteMicMode: audioCfg.mode.toString(),
                inviteAudioQ: audioCfg.bitrate.toString() + "|" + audioCfg.chan.toString(),
                inviteTitlePrefix: "Edit Invite:",
                inviteGUID: inviteGUID,
                inviteSessionGUID: data.inviteSession,
                inviteDisablePassword: _inviteDisablePassword,
                inviteDisableEmail:_inviteDisableEmail
            });
            

            }).catch((err) => {
                console.log("ERR handleEditInvite", err)
                 this.props.setAuthError(err);
            });

        

    }


    padZero(num) {
        if (num < 10) {
          return `0${num}`;
        }
        return `${num}`;
      }
  
    formatDate(dateString) {
        const dateTime = new Date(dateString);
        return [
            dateTime.getUTCFullYear(),
            this.padZero(dateTime.getUTCMonth() + 1),
            this.padZero(dateTime.getUTCDate()),
            "T",
            this.padZero(dateTime.getUTCHours()),
            this.padZero(dateTime.getUTCMinutes()) + "00Z"
        ].join("");
    }

    createIcalObject = (event) => {

     
        if(event.recurrenceRule) {
  
          // with repeat rules
          const icalObj = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART:" + this.formatDate(event.startDate),
            "DTEND:" + this.formatDate(event.endDate),
            "SUMMARY:" + event.name,
            "RRULE:" + event.recurrenceRule,
            "END:VEVENT",
            "END:VCALENDAR"
          ].join("\n");
    
          return icalObj;
    
        } else {
  
          // no repeat rules
          const icalObj = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART:" + this.formatDate(event.startDate),
            "DTEND:" + this.formatDate(event.endDate),
            "SUMMARY:" + event.name,
            "END:VEVENT",
            "END:VCALENDAR"
          ].join("\n");
    
          return icalObj;
    
  
        }
  
      }

    inviteCheck = ev => {
        console.log("onSubmit", ev);
        ev.preventDefault();
        if (ev.currentTarget.checkValidity() === false) {
          console.log("NOT VALID");
          return;
        } else {
          console.log("IS VALID");
        }
    }

    validateEmail = (emailAddr) => {
        
        if(this.state.inviteDisableEmail) { //if we don't use email, dont validate it...
          return true;
        }

        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddr)) {
            return true;
        }
            
        return false;
    }

    onClickDoInvite = () => {

        const invite = {
          sessionID: this.state.inviteModalSession.sessionID,
          name: this.state.inviteName,
          email: this.state.inviteEmail,
          startDate: this.state.inviteValidFrom,
          endDate: this.state.inviteValidTo,
        };
    
        if (invite.name === undefined || invite.name === "") {
          alert(<>Please enter Name</>, {
            variant: "info",
          });
          document.getElementById("inviteName").focus(); 

        } else if ((invite.email === undefined || invite.email === "") && this.state.inviteDisableEmail === false) {
          alert(<>Please enter Email</>, {
            variant: "info",
          });
          document.getElementById("inviteEmail").focus(); 

        } else if (!this.validateEmail(invite.email)) {
            alert(<>Email address not valid</>, {
                variant: "info",
              });
              document.getElementById("inviteEmail").focus(); 
        } else { 
    
          //Okay, everything looks good, lets send invite!

          let audioCfgObj = {
            mode: 1,
            chan: 1,
            bitrate: 64,
          };
    
          if(this.state.inviteAudioQ){
            const audioQ = this.state.inviteAudioQ.split('|');
    
            if(audioQ.length > 1)
            {
              audioCfgObj = {
                mode: parseInt(this.state.inviteMicMode),
                bitrate: audioQ[0],
                chan: audioQ[1],
              };
            }
          }


          this.setState({ inviteModalOpen: false });

          if(this.state.isEditMode) {

            const putData = {
                inviteGUID: this.state.inviteGUID,
                sessionId: this.state.inviteSessionGUID,
                name: invite.name,
                email: invite.email,
                vcal: this.createIcalObject(invite),
                audioCfg: JSON.stringify(audioCfgObj),
                noemail: 1,
              };
        
            //console.log("Edit Invite", putData);
              
            axios
                .put("./api/invite", putData, this.tokenHeader)
                .then((res) => {

                    pushNotification("Successfully updated Invite for: " + invite.name, {
                    variant: "success",
                    });

                    this.loadAgentsInGroup(0);
                })
                .catch((err) => {
                    pushNotification("Error Updating Invite: " + err.message);
                    console.log("ERR - update invite", err)
            });

          } else {

            let _noPwd = 0;
            let _noEmail = 0;
           
            if(this.state.inviteDisablePassword) {
              _noPwd = 1;
            }

            if(this.state.inviteDisableEmail) {
              _noEmail = 1;

              pushNotification("Creating Invite for: " + invite.name + ", please wait...", {
                variant: "success",
              });

            } else {
              _noEmail = 0;
            //  invite.email = "";
              
              pushNotification("Creating and Sending Invite for: " + invite.name + ", please wait...", {
                variant: "success",
              });
            }

            const postData = {
                sessionID: invite.sessionID,
                name: invite.name,
                email: invite.email,
                vcal: this.createIcalObject(invite),
                audioCfg: JSON.stringify(audioCfgObj),
                nopwd: _noPwd,
                noemail: _noEmail,
              };

            // console.log("Create Invite", postData);

            axios
                .post("./api/invite", postData, this.tokenHeader)
                .then((res) => {

                  if(_noEmail === 0) {

                    pushNotification("Successfully sent Invite to: " + invite.name, {
                    variant: "success",
                    });

                  } else {

                    if(res.data && res.data.inviteUrl) {
                      navigator.clipboard.writeText(res.data.inviteUrl);

                      alert(invite.name + "´s invite link now stored in Clipboard", {
                        variant: "info",
                      });

                    }

                  }

                    this.loadAgentsInGroup(0);
                })
                .catch((err) => {
                    pushNotification("Error Sending Invite: " + err.message);
                    console.log("ERR - add invite", err)
            });
            
          }
          
        }
    };

     startDateChange = (date) => {
        var unixTime = date.getTime();
        this.setState({ inviteValidFrom: unixTime });
    
        let newEndDate = new Date(unixTime);
        newEndDate.setHours((newEndDate.getHours() + 6));
    
        /*TODO*/
        //set enddate one day later than start date!
        var unixTime2 = newEndDate.getTime();
        this.setState({ inviteValidTo: unixTime2 });
    
        //console.log("Date",this.state.inviteValidFrom,this.state.inviteValidTo)
      };
    
      endDateChange = (date) => {
        var unixTime2 = date.getTime();
        this.setState({ inviteValidTo: unixTime2 });
    
       // console.log("Date",this.state.inviteValidFrom,this.state.inviteValidTo)
      };
    
      inviteNameChange = (event) => {
        this.setState({ inviteName: event.target.value });
      };
    
      inviteEmailChange = (event) => {
        this.setState({ inviteEmail: event.target.value });
      };
    
      inviteMicModeChange = (event) => {
        this.setState({ inviteMicMode: event.target.value });
      };
    
      inviteAudioQChange = (event) => {
        this.setState({ inviteAudioQ: event.target.value });
      };

      inviteNoPwdChange = (value) => {
        if(value) {
          this.setState({ inviteDisablePassword: 1 });
        } else {
          this.setState({ inviteDisablePassword: 0 });
        }
      };

      inviteNoEmailChange = (value) => {
        this.setState({inviteDisableEmail: value})

        if(value) {
          this.setState({ inviteDisablePassword: 1 });
        } else {
          this.setState({ inviteDisablePassword: 0 });
        }

        if(value) {
          this.setState({inviteEmailPlaceholder : ""});
        } else {
          this.setState({inviteEmailPlaceholder : "Email address"});
        }
      };

    renderModalInvite() {

        if(!this.state.inviteModalSession) {
            return null;
        }

        return(
          <form onSubmit={this.inviteCheck}>
          <Modal
              
              key={`inviteModal_${this.state.userGUID}`}
              open={this.state.inviteModalOpen}
              className="session-inviteModal"
              onEscapeKeyDown={() => this.setState({ inviteModalOpen: false })}
              buttons={[
                 <button className="uic-btn btn-grey" onClick={() => this.setState({ inviteModalOpen: false })} key={`btnInviteCancel_${this.state.userGUID}`}>
                  Cancel
                </button>,
                <button className="uic-btn btn-blue" onClick={this.onClickDoInvite} color="primary" key={`btnSendCancel_${this.state.userGUID}`}>
                 {this.state.isEditMode ? (<>Update Invite</>):(<>Create Invite</>)}
                </button>,
              ]}
            >
              <h2 className="panel-subSection-header">{this.state.inviteTitlePrefix} {this.state.inviteTitle}</h2>
              
              <Table
                
                alignLabelsLeft
                yPad={10}
                rows={[
                  [
                    "Name",
                    <input
                      style={{width:"100%"}} 
                      id="inviteName"
                      className="uic-input"
                      value={this.state.inviteName}
                      onChange={this.inviteNameChange}
                      placeholder="Name" 
                      name="inviteName" 
                      autoComplete="off" 
                      type="text"
                      required 
                    />,
                  ],
                  [
                   "Email",
                    <input
                      style={{width:"100%"}} 
                      id="inviteEmail"
                      className="uic-input"
                      value={this.state.inviteEmail}
                      onChange={this.inviteEmailChange}
                      placeholder={this.state.inviteEmailPlaceholder}
                      name="inviteEmail" 
                      autoComplete="off" 
                      type="email"
                      required 
                      disabled={this.state.inviteDisableEmail} 
                    />,
                  ],
                  [
                    "Don't Send Email",
                    <Toggle 
                    checked={this.state.inviteDisableEmail} 
                      onChange={this.inviteNoEmailChange}
                      disabled={this.state.isEditMode} 
                    />
                  ]
                  , [
                    "Don't Use Password",
                    <Toggle 
                      
                      checked={this.state.inviteDisablePassword} 
                      onChange={this.inviteNoPwdChange}
                      disabled={this.state.isEditMode || this.state.inviteDisableEmail} 
                    />
                  ],
                  [
                    "Invite Valid From",
                    <DatePicker
                      selected={this.state.inviteValidFrom}
                      onChange={this.startDateChange}
                      showTimeSelect
                      minDate={new Date()}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="yyyy-MM-dd HH:mm"
                      disabledKeyboardNavigation
                      showWeekNumbers
                      className="uic-input"
                    />,
                  ],
                  [
                    "Invite Valid To",
                    <DatePicker
                      selected={this.state.inviteValidTo}
                      onChange={this.endDateChange}
                      showTimeSelect
                      minDate={new Date()}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="yyyy-MM-dd HH:mm"
                      disabledKeyboardNavigation
                      showWeekNumbers
                      className="uic-input"
                    />,
                  ],
                  [
                    "Microphone Mode",
                    <select
                      className="uic-input"
                      onChange={this.inviteMicModeChange}
                      value={this.state.inviteMicMode}
                    >
                      <option value="1">Default</option>
                      <option value="2">Suggest Headset</option>
                      <option value="3">Suggest Handset</option>
                      <option value="4">Suggest Handsfree</option>
                      <option value="5">Force Headset</option>
                      <option value="6">Force Handset</option>
                      <option value="7">Force Handsfree</option>
                    </select>,
                  ],
                  [
                    "Audio Quality",
                    <select
                      className="uic-input"
                      onChange={this.inviteAudioQChange}
                      value={this.state.inviteAudioQ}
                    >
                      <option value="0|0">Default</option>
                      <option value="24|1">Speech Low (24kbps mono)</option>
                      <option value="48|1">Speech Medium (48kbps mono)</option>
                      <option value="64|1">Speech High (64kbps mono)</option>
                      <option value="96|1">Speech Better (96kbps mono)</option>
                      <option value="128|1">Speech Superb (128kbps mono)</option>
    
                      <option value="64|2">Music Low (64kbps stereo)</option>
                      <option value="96|2">Music Medium (96kbps stereo)</option>
                      <option value="128|2">Music High (128kbps stereo)</option>
                      <option value="192|2">Music Better (192kbps stereo)</option>
                      <option value="256|2">Music Superb (256kbps stereo)</option>
                    </select>,
                  ]
                  
                ]}
              />
            </Modal></form>
        )
    }

//inviteMode
    renderInviteMode(iMode) {

        switch(iMode){
            case 1:
                return (<Chip size="small" color="primary" variant="outlined" label="LiveNet" style={{marginTop:"-2px"}} />);

            case 3:
                return (<Chip size="small" color="primary" variant="outlined" label="VIP" style={{marginTop:"-2px"}} />);

            case 5:
                return (<Chip size="small" color="primary" variant="outlined" label="Axia iQs" style={{marginTop:"-2px"}} />);

            case 7:
                return (<Chip size="small" color="primary" variant="outlined" label="Zip/One" style={{marginTop:"-2px"}} />);

            default:
                return null;  
        }
    } 

    formatAppInvideCode = (code) => {

      try{
        const spacedCode = code.substring(0, 3) + " " + code.substring(3, 3+3) + " " + code.substring(6, 3+6);
        return spacedCode;
      } catch {
        return code;
      }

    }

    renderInfoIconSession = (item, session) => {

      let hasPwd = (item.noPwd) ? "No": "Yes";

      const markdown = `
      - Session: ` + session.sessionTitle + `
      - Invitee: ` + item.inviteName + `
      - App Code: ` + this.formatAppInvideCode(item.appCode) + `
      - Session Id: ` + session.sessionID + `
      - Agent Id: ` + session.agentGuid + `
      - Agent Group Id: ` + session.agentGroupGuid + `
      - Invite Id: ` + item.inviteGUID + `
      - Password protected: ` +  hasPwd;
    
      return (<InfoTooltip children={markdown} style={{marginLeft:"10px"}} />)
    }

    renderInfoIconAgent = (agent) => {
      const markdown = `
      - Title: ` + agent.title + `
      - Agent: ` + agent.agentGUID + `
      - Agent Group: ` + agent.agentGroupGUID;
      
      return (<InfoTooltip children={markdown} style={{marginLeft:"5px", marginTop:"-3px"}} />)
    }

    renderItemConnectionStatus = (conState, hasExpired, isBlocked) => {

        // https://gitlab.zephyr.com/lib/webrtcc/-/tree/master/web#status-events

      if(hasExpired || isBlocked) {
        return null;
      }

      switch(conState){
        case 0:
        case 4:
            return (<Chip size="small" style={{color: "#cc3e4a", borderColor:"#cc3e4a"}}  variant="outlined" label="OFF-LINE" />);

        case 6:    
        case 8:    
        case 9:
          return (<Chip size="small" style={{color: "#cc3e4a", borderColor:"#cc3e4a"}}  variant="outlined" label="DISCONNECT" />);    

        case 1:
        case 10:
        case 12:    
            return (<Chip size="small" style={{color: "#e6e600", borderColor:"#e6e600"}} variant="outlined" label="CONNECTING" />);  

        case 11:  
          return (<Chip size="small" style={{color: "#c0c0c0", borderColor:"#c0c0c0"}} variant="outlined" label="RECONNECT" />);  


        case 2: //waiting for peer
            return (<Chip size="small" style={{color: "#c04dff", borderColor:"#c04dff"}} variant="outlined" label="CONNECTING" />);

        case 3:
            return (<Chip size="small" style={{color: "#7bc93b", borderColor:"#7bc93b"}} variant="outlined" label="ON-LINE" />);

        case 5:
            return (<Chip size="small" style={{color: "red", borderColor:"red"}}  variant="outlined" label="FAILED" />);
      

        default:
            return (<Chip size="small" style={{color: "#c0c0c0", borderColor:"#c0c0c0"}} variant="outlined" label="N/A" />);  
      }

    }

    renderIsValid = (isValid, isExpired, isBlocked) => {

        if(isBlocked) {
          return (<BlockIcon style={{color: "#666"}} />);
        }

        if(isValid && !isExpired) {
          return (<CheckIcon style={{color: "#7bc93b"}} />);
        }

        if(isExpired) {
          return (<CloseIcon style={{color: "#cc3e4a"}} />);
        } 
    }

    agentCallback = (isOK, msg) =>{
        if(isOK) { 
          console.log("agentCallback", isOK, msg);
          this.loadAgentsInGroup(0);
        }
    }

    renderStunWarning() {

      if(!this.state.iceConfigItems) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      const stunItems = this.state.iceConfigItems.filter(f => f.iceType===1); 
      
      if(stunItems) {
        
        if(stunItems.length === 0) {  
          return(
            <Grid item xs={12} >
              <Panel style={{backgroundColor:"#df6320", height: "60px"}}>
                <div style={{position:"relative", marginTop:"0px"}}>
                <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
                STUN server is not configured — you need one STUN servers, please configure!
                <Link to={"settings"} > 
                  <Button color="gray" style={{ margin: "6px" }}> Show Settings page</Button>
                </Link></span>
                </div>
              </Panel>
            </Grid>
          )
        }
      }
    }

    renderTurnWarning() {

      if(!this.state.iceConfigItems) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      const turnItems = this.state.iceConfigItems.filter(f => f.iceType===2); 
      
      if(turnItems) {
        
        if(turnItems.length === 0) {  
          return(
            <Grid item xs={12} >
              <Panel style={{backgroundColor:"#df6320", height: "60px"}}>
                <div style={{position:"relative", marginTop:"0px"}}>
                <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
                TURN servers are not configured — please consider adding TURN servers. 
                <Link to={"settings"} > 
                  <Button color="gray" style={{ margin: "6px" }}> Show Settings page</Button>
                </Link></span>
                </div>
              </Panel>
            </Grid>
          )
        }
      }
    }

    renderEmailWarning() {

      if(!this.state.firstsConfig) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      if(this.state.firstsConfig && this.state.firstsConfig.isFirstMailConfig) {

        return(
          <Grid item xs={12} >
            <Panel style={{backgroundColor:"#df6320", height: "60px"}}>
              <div style={{position:"relative", marginTop:"0px"}}>
              <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
              Email settings are not configured — please select service and configure it. 
              <Link to={"settings"} > 
                <Button color="gray" style={{ margin: "6px" }}> Show Settings page</Button>
              </Link></span>
              </div>
            </Panel>
          </Grid>
        )
      }
    }


    renderServerInfo() {

      if(this.state.serverInfo) {

        return (
        <>
        - UTC: {this.state.serverInfo.timeUtcStr} <br/>
        - Uptime: {this.state.serverInfo.upTimeStr} <br/>
        - Mem: {this.state.serverInfo.mem} k<br/>
        - Version: {this.state.beaconInfo.version} <br/>
        </>)

      } else {
        return null;
      }
    }

    renderSignalInfo() {

      if(this.state.signalInfo) {

        return (
        <>
        - Active Sessions: {this.state.signalInfo.numRooms} <br/>
        - Active Peers: {this.state.signalInfo.numPeers} <br/>

        </>)

      } else {
        return null;
      }
    }

    render() { 

        //console.log("AGs", this.state.agentGroupItems);

        return (<React.Fragment> 
            { this.renderModalInvite() }
            
            {!this.state.hasAppSettings && (
                <><MuiAlert severity="warning">Please configure 'App Setup' goto the Settings page</MuiAlert><br/></>
            )}
            <Grid container spacing={2}>
                {this.renderStunWarning()}
                {this.renderTurnWarning()}
                {this.renderEmailWarning()}

                <Grid item xs={8} >
                    <Panel title="Dashboard">
                        Future information
                    </Panel>
                </Grid>

                <Grid item xs={4} >
                    <Panel title="User">
                       {this.state.userName} ({this.state.userRole})
                    </Panel>
                </Grid>

               
                <Grid item xs={12}>
                    
                    <Panel title={"Invites"}>
                    
                    {(!this.state.agentGroupLoaded) && ( 
                        <>
                         <div> <HourglassEmptyIcon style={{color:"#f58838"}} /> <span style={{position:"relative", top:"-5px"}}>Loading...</span></div>
                        </>
                    )}
                   

                    {this.renderAgentsPagination()}

                    {(this.state.agentGroupLoaded && this.state.agentGroupCount === 0) && ( 
                      
                        <div style={{position:"relative", marginTop:"20px"}}>
                          <WarningIcon  style={{color: "#df6320", top:"-10px", position:"relative"}} />
                          <span style={{color: "#ffffff", top:"-15px", marginLeft:"7px", position:"relative", fontWeight:"500"}} >  
                          No Agents found!
                          </span>
                          </div>
                       
                    )}

                    {this.state.agentGroupItems.map((itemAgent) => (    
                        <CollapsablePanelDashboard userRole={this.state.userRole} callBack = {this.agentCallback} style={{backgroundColor:"#181818"}} id={`db_pnl_${itemAgent.agentGUID}`} key={itemAgent.agentGUID} startCollapsed={true} dataObj={itemAgent} token={this.tokenHeader} title={<><ListIcon style={{color:"#48ACF4", position:"relative", top:"5px"}}  /> {itemAgent.title} <span style={{color:"#48ACF4"}}>({itemAgent.invitesCount})</span></>} spacing={2}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 

                            {(itemAgent && itemAgent.sessions.length === 0) && ( 
                               <> <h5 style={{color:"#ffff76"}}>No Sessions found in "{itemAgent.title}" {this.renderInfoIconAgent(itemAgent)} </h5></>
                            )}

                            {itemAgent.sessions.map((sessionItem) => (    
                                
                                <CollapsablePanel id={`db_pnls_${sessionItem.sessionID}`} key={sessionItem.sessionID} startCollapsed={true} title={<><GroupIcon style={{color:"#48ACF4", position:"relative", top:"5px"}} /> {sessionItem.sessionTitle} {this.renderInviteMode(sessionItem.inviteMode)} </>} spacing={2}>
                                
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >  

                                    {this.renderInviteItems(itemAgent.invites, sessionItem) }

                                    </Grid>
                                </Grid>
                            </CollapsablePanel>
                            ))}

                            </Grid>
                        </Grid>
                    </CollapsablePanelDashboard>
                    ))}
                    </Panel>
                </Grid>
               
                <Grid item xs={12} sm={8}  md={6} lg={4} xl={4}>
                    <Panel title="Status">
                      { this.renderSignalInfo() }
                      { this.renderServerInfo() }
                    </Panel>
                </Grid>

            </Grid>   

        </React.Fragment>);
    }


}
 
export default Dashboard;