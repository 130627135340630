import React, { Component } from 'react';
import { Panel, Button, VuMeter, Col, Row, CollapsablePanel, Table, Box, IconButton} from "@telosalliance/ui-core";
import { pushNotification } from "@telosalliance/ui-core-framework";
import Grid from '@material-ui/core/Grid';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { format, addMinutes  } from 'date-fns'
import { TelosWebRTC, MediaDevices }  from "../component/WebRTC/teloswebrtc";

class WebRtcLiveNet extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          isLoaded:false,
          session: props.session,
          vuLocalL: -50,
          vuLocalR: -50,
          vuRemoteL: -50,
          vuRemoteR: -50,
          audioInputs:[],
          audioOutputs:[],
          videoInputs:[],
          selAudioIn: 'default',
          selAudioOut: 'default',
          dataChan:'',
          connectionInfo: '...',
          isAudioInMuted:false,
          isAudioOutMuted: false,
          isActive: false,
          serverTime: {nowTime: 0, upTime: 0},
          getStatsInfo: null,
          localTime: "--:--",
          conTime: "--:--",
          uiView: "call",
          isOnCall:false,
          isConnected: false,
         }

         this.mediaDevices = null;
         this.telosWebRTC = null;   
    }


    getSelectedAudioInput(){

      let item = localStorage.getItem('ln_audioDevInput')

      if(!item){
        item = "default";
      }

      this.setState({selAudioIn: item});

      return item;  
    }

    setSelectedAudioInput(item){


    }

    getSelectedAudioOutput(){

      let item = localStorage.getItem('ln_audioDevOutput')

      if(!item){
        item = "default";
      }

      this.setState({selAudioOut: item});

      return item;  
    }

    componentDidMount() {
      this.initClient();
    }

    initClient() {

      this.mediaDevices = new MediaDevices();

      this.mediaDevices.on('audioOut', (devices) => {
        this.setState({audioOutputs: devices}, () => {
          // wait until set, then set the selected devices
          this.setState({selAudioOut: this.getSelectedAudioOutput()})
        });
      });
  
      this.mediaDevices.on('audioIn', (devices) => {
        this.setState({audioInputs: devices}, () => {
          // wait until set, then set the selected devices
          this.setState({selAudioIn: this.getSelectedAudioInput(), isLoaded:true})
        });
      });
  
      this.mediaDevices.on('videoIn', (devices) => {
        this.setState({videoInputs: devices}); 
      });

      this.mediaDevices.on('error', (error) => {
        console.log("mediaDevices error", error);
      });

  


      /* TelosWebRTC */

      this.telosWebRTC = new TelosWebRTC();

      this.telosWebRTC.on('localVu', (data) => {
        this.setState({vuLocalL: data.dbL, vuLocalR: data.dbR})
      });

      this.telosWebRTC.on('remoteVu', (data) => {
        this.setState({vuRemoteL: data.dbL, vuRemoteR: data.dbR})
      });

      this.telosWebRTC.on('data', (data) => {
      //  console.log("data", data);
      });

      this.telosWebRTC.on('data-bin', (data) => {
       // console.log("data binary", data);
      });

      this.telosWebRTC.on('audio', (data) => {
        this.setState({isAudioInMuted: data.audioInMuted, isAudioOutMuted: data.audioOutMuted})
      });
      

      this.telosWebRTC.on('status', (data) => {

        if(data.tp === 1 ) { // only look at "ConnectionState"
          this.setState({connectionInfo: data.msg })
        
            if(data.data === 8) { //autoDisconnect
              pushNotification("You have been disconnected due to another client connected to this session", 
                { anchorOrigin:{ vertical: 'top', horizontal: 'center' }, variant: "warning", autoHideDuration: 10000 }
              )
            }

            if(data.data === 11) { //reconnecting

              this.setState({isOnCall:true});
              pushNotification("Reconnecting to session", 
                {  variant: "info" }
              )
            }

            if(data.data === 3) { //connected
              pushNotification("Connected to session", 
                {  variant: "success" }
              )
            }


            if(data.data === 1 || data.data === 2 || data.data === 3 || data.data === 11) {
              this.setState({isActive:true});
            }

            if(data.data === 1) {
              this.setState({isConnected:true});
            }

            if(data.data === 4 || data.data === 5 || data.data === 6 || data.data === 7 || data.data === 8 || data.data === 9) {
              this.setState({isActive:false, localTime: "--:--", conTime: "--:--", isOnCall:false, isConnected:false});
            }
        }

        // GetStats
        if(data.tp === 13) { // only look at "GetStats"
          
          //console.log("stats",data.data);
          
          if(data.data) { 
              this.setState({getStatsInfo: data.data})  
          }
       }

      
      });

      this.telosWebRTC.on('error', (error) => {
        console.log("error", error);
      });

      this.telosWebRTC.on('debug', (data) => {
       // console.log("debug", data);
      });

      this.telosWebRTC.on('time', (data) => {
        //console.log("time", data);

        var _lt = format(new Date(data.nowTime), 'HH:mm');

        var upDate = new Date(data.upTime);
        var _ct = format(addMinutes(upDate, upDate.getTimezoneOffset()), 'HH:mm');

        this.setState({serverTime: data, localTime: _lt, conTime: _ct});
      });

      this.telosWebRTC.on('eject', (data) => {
        //console.log("eject", data);
        this.props.refreshCallback(this.props.inviteId);
      });
      


      /*
      const initCfg = {
        autoResume: false,
        debug:false,
      }

      this.telosWebRTC.initWebRTC(initCfg);
     */
    }


    doConnect = () => {

      const audioConfig = JSON.parse(this.state.session.audioConfig);
   
      const config = {
        signal: this.state.session.signalConfig,
        sessionId: this.state.session.inviteSession,
        inviteId: this.state.session.inviteGUID,
        title: this.props.session.inviteName,
        audioIn: this.getSelectedAudioInput(),
        audioOut: this.getSelectedAudioOutput(),
        sendBitRate: parseInt(audioConfig.bitrate),
        sendStereo: (parseInt(audioConfig.chan) === 2) ? true : false,
        receiveStereo: true,
        debug: false,
        autoReconnect: true,
        getStats:false,
        vumeters: true,
        workletPath: "worklet/",
        promptNewUser: true,
      }
  
      this.telosWebRTC.connectWebRTC(config);

      this.setState({isActive:true, isOnCall:true});

    }

    componentWillUnmount(){
     this.telosWebRTC.closeWebRTC();
    }

    doClose = () => {
      this.setState({isOnCall:false});
     this.telosWebRTC.closeWebRTC();
    }

    doTest = () => {
  
    }

    changeAudioInput = (event) => {

      var item = event.target.value;
      localStorage.setItem('ln_audioDevInput', item);
      this.setState({selAudioIn: item});
    };

    changeAudioOutput = (event) => {

      var item = event.target.value;
      localStorage.setItem('ln_audioDevOutput', item);
      this.setState({selAudioOut: item});
    };

    doAudioInMute = (muted) => {
      this.telosWebRTC.muteAudioIn(muted);
    }

    doAudioOutMute = (muted) => {
      this.telosWebRTC.muteAudioOut(muted);
    }

    bytesToSize(bytes) {
      var k = 1000;
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes <= 0) {
          return '0 Bytes';
      }
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10);
      
      if(!sizes[i]) {
          return '0 Bytes';
      }

      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    }

    renderStats(){

      if(this.state.getStatsInfo && this.state.getStatsInfo.connectionType && this.state.getStatsInfo.audio) {


        return(  
          <Table 
            params 
            striped={false}
            rows={[
              ["Local", 
              this.state.getStatsInfo.connectionType.local.candidateType + " (" + this.state.getStatsInfo.connectionType.local.transport.join(', ') + ") " +  this.state.getStatsInfo.connectionType.local.ipAddress
              ],
              ["Remote", 
              this.state.getStatsInfo.connectionType.remote.candidateType + " (" + this.state.getStatsInfo.connectionType.remote.transport.join(', ') + ") " +  this.state.getStatsInfo.connectionType.remote.ipAddress
              ],
              ["Audio", 
              "Sent: " + this.bytesToSize(this.state.getStatsInfo.audio.bytesSent) + ", Received: " + this.bytesToSize(this.state.getStatsInfo.audio.bytesReceived) + ", Latency: " + this.state.getStatsInfo.audio.latency + " ms, Packet Loss: " + this.state.getStatsInfo.audio.packetsLost
              ],
            ]}
            />
        ); 
        
      } else {
        return null;
      }
    }



    render() { 

        if(!this.state.audioInputs){
          return null;
        }

        return (
        <React.Fragment>
        <center style={{ marginTop:"30px" }}>


        <Panel style={{ minWidth: 360, maxWidth: 1200, padding:"20px" }}>
        
        <Row>
          <Col>
            <h2>Welcome - {this.props.session.inviteName}</h2>
          </Col>
        </Row>


        <Grid 
          style={{ marginTop:"5px"}}
          container 
          
          spacing={4}
        >   
   
          <Grid item xs={12} md={3} sm={12}> 

            <Box variant="solid">
            
            {this.state.isOnCall ? (
              <Button style={{position:"relative", width:"170px", padding:"10px", margin:"5px", fontWeight:"bold", zIndex:"2"}} color="light-red" outlined onClick={()=>{ this.doClose() }} ><><CallEndIcon style={{position:"relative", top:"5px"}} /><span style={{position:"relative", top:"-2px", marginLeft:"5px"}}>END CALL</span></></Button>
            ) : (
              <Button style={{position:"relative", width:"170px", padding:"10px", margin:"5px", fontWeight:"bold", zIndex:"2"}} color="green" outlined onClick={()=>{ this.doConnect() }} ><><CallIcon style={{position:"relative", top:"4px"}} /><span style={{position:"relative", top:"-2px", marginLeft:"5px"}}>CONNECT</span></></Button>
            )}
           
              <div style={{paddingTop:"20px", paddingBottom:"10px"}}>{this.state.connectionInfo}</div>

            </Box>

 
          </Grid> 


          <Grid item xs={12} md={9} sm={12}>  

              <Box variant="solid" style={{background:"#1117", minWidth:"800px", borderRadius: "4px" }}>
              <div className="lnVuGrid">
                <Grid container spacing={10} >
                
                  <Grid item xs={12} md={6} sm={12}>

                        <span className="lnSmallLabel">Audio In (Local)</span>
                        <Table 
                        style={{position:"relative", marginTop:"-25px", left:"-250px"}}
                        params 
                        striped={false}
                        rows={[
                          [
                        <>
                          <VuMeter 
                          style={{position:"relative", left:"-37px"}}
                          value={this.state.vuLocalL}
                          peakHoldValue={this.state.vuLocalL}
                          floorLevel="-50"
                          label="L"
                          attack={0.15}
                          decay={0.04}
                          peakHoldLineWidth={1}
                          zone1LevelColor="#f1f1f1"
                          peakHoldColor="#f1f1f1"
                          backgroundColor="#101010"
                          height={300}
                          width={20}
                          horizontal
                        />
                        </>],
                        [<>
                        <VuMeter
                          style={{position:"relative", marginTop:"-12px"}}
                          value={this.state.vuLocalR}
                          peakHoldValue={this.state.vuLocalR}
                          floorLevel="-50"
                          label="R"
                          attack={0.15}
                          decay={0.04}
                          peakHoldLineWidth={1}
                          zone1LevelColor="#f1f1f1"
                          peakHoldColor="#f1f1f1"
                          backgroundColor="#101010"
                          height={300}
                          width={20}
                          horizontal
                          showTicks="right"
                      />
                        </>]
                        ]}
                      /> 

                        {this.state.isAudioInMuted ? (
                          <IconButton icon={MicOffIcon} tooltip="UnMute Mic" style={{ marginTop:"8px", color:"#cc3e4a"}}  onClick={(event) => { this.doAudioInMute(false) }} />
                        ) : (
                          <IconButton icon={MicIcon} tooltip="Mute Mic" style={{ marginTop:"8px"}}  onClick={(event) => { this.doAudioInMute(true) }} />
                        )}

                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                      
                        <span className="lnSmallLabel">Audio Out (Remote)</span>
                        <Table 
                        style={{position:"relative", marginTop:"-25px", left:"-250px"}}
                        params 
                        striped={false}
                        rows={[
                          [
                        <>
                          <VuMeter 
                          style={{position:"relative", left:"-37px"}}
                          value={this.state.vuRemoteL}
                          peakHoldValue={this.state.vuRemoteL}
                          floorLevel="-50"
                          //showTicks="left"
                          label="L"
                          attack={0.15}
                          decay={0.04}
                          peakHoldLineWidth={1}
                          zone1LevelColor="#7ac900"
                          peakHoldColor="#7ac900"
                          backgroundColor="#101010"
                          height={300}
                          width={20}
                          horizontal
                        />
                        </>],
                        [<>
                          <VuMeter
                            style={{position:"relative", marginTop:"-12px"}}
                            value={this.state.vuRemoteR}
                            peakHoldValue={this.state.vuRemoteR}
                            floorLevel="-50"
                            label="R"
                            attack={0.15}
                            decay={0.04}
                            peakHoldLineWidth={1}
                            zone1LevelColor="#7ac900"
                            peakHoldColor="#7ac900"
                            backgroundColor="#101010"
                            height={300}
                            width={20}
                            horizontal
                            showTicks="right"
                        />
                          </>]
                          ]}
                        />


                        {this.state.isAudioOutMuted ? (
                          <IconButton icon={VolumeOffIcon} tooltip="UnMute Speaker" style={{ marginTop:"8px", color:"#cc3e4a"}}  onClick={(event) => { this.doAudioOutMute(false) }} />
                        ) : (
                          <IconButton icon={VolumeUpIcon} tooltip="Mute Speaker" style={{ marginTop:"8px"}}  onClick={(event) => { this.doAudioOutMute(true) }} />
                        )}

                      </Grid> 
      
                  </Grid>
                </div>
                </Box>
   
          </Grid>
               
          
        </Grid> 
       

         <Grid 
            style={{ marginTop:"5px"}}
            container 
            direction="row"
            justify="space-between"
            spacing={4}
          >   
   
            <Grid item xs={12} md={8} sm={12}>  
              <CollapsablePanel startCollapsed={true} title="Advanced Settings" id={`cpnl1_${this.state.session.inviteSession}`} >

                <Table 
                  params 
                  striped={false}
                  rows={[
                    ["Audio In", 
                    <>
                      <select
                      name="cmdAudioIn"
                      key="cmdAudioIn"
                      className="uic-input"
                      onChange={this.changeAudioInput}
                      value={this.state.selAudioIn}
                      disabled={this.state.isActive}
                      >
                        {this.state.audioInputs.map((device, index) => (
                          <option
                            value={device.deviceId}
                            key={index}
                          >
                            {device.name}
                          </option>
                        ))}

                      </select>
                    </>
                    ],
                    ["Audio Out", 
                    <>
                      <select
                        className="uic-input"
                        onChange={this.changeAudioOutput}
                        name="cmdAudioOut"
                        key="cmdAudioOut"
                        value={this.state.selAudioOut}
                        disabled={this.state.isActive}
                      >
                        {this.state.audioOutputs.map((device, index) => (
                          <option
                            value={device.deviceId}
                            key={index}
                          >
                            {device.name}
                          </option>
                        ))}

                      </select>
                    </>
                    ],
                  ]}
                  />  

              <span className="lnSmallLabel"><i>Must be set before connecting</i></span>
              </CollapsablePanel>  

            </Grid>

            <Grid item xs={12} md={4} sm={12} id={`cpnl2_${this.state.session.inviteSession}`} >
                <CollapsablePanel startCollapsed={true} title="Stats">

                <Table 
                  params 
                  striped={false}
                  rows={[
                    ["Local time", 
                    <>
                      {this.state.localTime}
                      
                    </>
                    ],
                    ["Connection Time", 
                    <>
                      {this.state.conTime}
                    </>
                    ],
                  ]}
                  />  
 
                 {this.renderStats()}

                </CollapsablePanel>
            </Grid>

          </Grid>
          


        </Panel>


          <br/>    
          <span className="lnSmallLabelStealth">
          Session: {this.props.session.inviteSession} <br/>
          Invite: {this.state.session.inviteGUID}
          </span>
          
      </center>
     

      


      </React.Fragment> );
    }
}
 
export default WebRtcLiveNet;