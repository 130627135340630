import React, { Component } from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { confirm, prompt } from "@telosalliance/ui-core-framework";
import axios from "axios";

class CollapsablePanelDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isCollapsed:true,
      mouse: {
        X: null,
        Y: null,
      }
     }
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({mouse: {
      X: event.clientX - 2,
      Y: event.clientY - 4,
    }})
   
  };

  handleClose = () => {
    this.setState({mouse: {
      X: null,
      Y: null,
    }})
  };

  updateAgentTitle = (id, newTitle) =>{

    const agentRename = {
      guid: id,
      title: newTitle,
    }

    axios
      .put("./api/renameagent", agentRename, this.props.token)
      .then((res) => {
        //console.log("RECEIVED: ", res.data);
        this.props.callBack(true, "ok");
      })
      .catch((err) => {
        this.props.callBack(false, err.message);
        console.log("ERR - updateAgentTitle: ", err);
      });

  }

  deleteAgent = (id) => {

    axios.delete("./api/agent?guid=" + id, this.props.token).then((res) => {
      this.props.callBack(true, "ok");
    }).catch((err) => {
      this.props.callBack(false, err.message);
      console.log("ERR - deleteAgent: ", err);
    });
  }

  render() { 

    let isMenuDisbled = true;

    if(this.props.userRole === "Admin") {
      isMenuDisbled = false;
    }

    return (
      <>
      <div
        style={this.props.style}
        className={`panel collapsablePanel ${this.state.isCollapsed ? "isCollapsed" : ""} ${this.props.className}`} 
      >
        <div
          className="titleContainer"
          onClick={() =>  this.setState({isCollapsed: !this.state.isCollapsed }) }  onContextMenu={this.handleClick}
          style={{ cursor: 'context-menu' }}
        >
          <ExpandMoreIcon className="collapseBtn" />
          {this.props.title && <h2 className="panel-title" >{this.props.title}</h2>}
        </div>
        <div className="panel-body">{this.props.children}</div>
      </div>
  
      <Menu
          id="simple-menu"
          keepMounted
          open={this.state.mouse.Y !== null}
          onClose={this.handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            this.state.mouse.Y !== null && this.state.mouse.X !== null
              ? { top: this.state.mouse.Y, left: this.state.mouse.X }
              : undefined
          }
        >  
          <MenuItem 
            disabled={isMenuDisbled}
            onClick={async (event) => {
              event.preventDefault();
              
              this.setState({mouse: {
                X: null,
                Y: null,
              }})

              const data = await prompt(
                <>
                  Title:
                </>,
                {
                  promptValue: this.props.dataObj.title,
                  autoSelect: true,
                }
              );
    
              if(!data) return;
              this.updateAgentTitle(this.props.dataObj.agentGUID, data);
    
          }}
          >Rename</MenuItem>
          <Divider />
          <MenuItem 
            disabled={isMenuDisbled}
            onClick={async (event) => {
              event.preventDefault();
              this.setState({mouse: {
                X: null,
                Y: null,
              }})

              if (
              await confirm(
                  <>
                  Sure you want to remove <b>{this.props.dataObj.title}</b> and all it's Sessions and Invites ?
                  </>,
                  { variant: "warning" }
              )
              ) {
                this.deleteAgent(this.props.dataObj.agentGUID);
              }
          }}
          >Delete</MenuItem> 
        </Menu>
      </>
    );
  }
}
 
export default CollapsablePanelDashboard;