import { appInit, render } from "@telosalliance/ui-core-framework";

import App from "./App";
import "./app.css";

//import reportWebVitals from "./reportWebVitals";

appInit(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    // console.groupEnd();
    // console.group("%c------------ Reload ------------", "color: #375");
    console.clear();

    render(NextApp);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
